<template>
  <div style="min-height: 1700px;">
    <b-table
      class="report-table text-left"
      responsive="false"
      :items="datas"
      :fields="fields"
      label-sort-asc=""
      label-sort-desc=""
      label-sort-clear=""
      foot-clone
    >
      <template #head()="scope">
        <div class="text-nowrap">{{ scope.label }}</div>
      </template>

      <template #cell(stt)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(player)="row">
        <router-link
          class="text-primary"
          :to="{
            name: 'reportPlayerDetail',
            params: { playerId: row.item.player_id },
            query: { from: from, to: to }
          }"
          >{{ row.item.player }}
        </router-link>
      </template>
      <template #cell(total_sms)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(winloss)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(percent)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="$emit('on-bao-so', row.item.player_id)"
          v-b-modal.modal-baoso
        >
          Sổ
        </b-button>
      </template>

      <template #foot(stt)>
        <span></span>
      </template>
      <template #foot(player)>
        <span>Tổng:</span>
      </template>
      <template #foot(total_sms)>
        <span>{{ summary.total_sms }}</span>
      </template>
      <template #foot(turn_over_2d)><span></span></template>
      <template #foot(turn_over_3d)><span></span></template>
      <template #foot(turn_over_da)><span></span></template>
      <template #foot(turn_over)
        ><span>{{ format(summary.turn_over) }}</span></template
      >
      <template #foot(amount)
        ><span>{{ format(summary.amount) }}</span></template
      >
      <template #foot(win)
        ><span>{{ format(summary.win) }}</span></template
      >
      <template #foot(winloss)>
        <span :class="summary.winloss < 0 ? 'text-danger' : 'text-primary'">{{
          format(summary.winloss)
        }}</span>
      </template>
      <template #foot(percent)><span></span></template>
      <template #foot(win_point)><span></span></template>
      <template #foot(actions)><span></span></template>
    </b-table>
    <b-row>
      <b-col cols="3" class="text-left">
        <b-form-select
          v-model="perPage"
          :options="optionPerpage"
        ></b-form-select>
      </b-col>
      <b-col cols="9" class="text-right">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { Utilities } from "@/utilities";
import moment from "moment";

export default {
  name: "report-table",
  data() {
    return {
      datas: [],
      summary: [],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      search: "",
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      fields: [
        "stt",
        {
          key: "player",
          label: "Khách hàng",
          sortable: true
        },
        {
          key: "total_sms",
          label: "Số tin",
          sortable: true
        },
        {
          key: "turn_over_2d",
          label: "2D",
          sortable: true,
          formatter: "format"
        },
        {
          key: "turn_over_3d",
          label: "3D|4D",
          sortable: true,
          formatter: (value, key, item) => {
            return this.format(item.turn_over_3d + item.turn_over_4d);
          }
        },
        {
          key: "turn_over_da",
          label: "Đá|Đá xiên",
          sortable: true,
          formatter: (value, key, item) => {
            return this.format(item.turn_over_da + item.turn_over_dx);
          }
        },
        {
          key: "turn_over",
          label: "Tiền xác",
          sortable: true,
          formatter: "format"
        },
        {
          key: "amount",
          label: "Thực thu",
          sortable: true,
          formatter: "format"
        },
        {
          key: "win",
          label: "Tiền trúng",
          sortable: true,
          formatter: "format"
        },
        {
          key: "winloss",
          label: "Thắng/Thua",
          sortable: true,
          formatter: "formatWinloss"
        },
        {
          key: "percent",
          label: "Theo",
          sortable: true,
          formatter: (value, key, item) => {
            return item.percent == 0
              ? ""
              : this.formatWinloss((item.winloss * item.percent) / 100.0) +
                  " (" +
                  item.percent +
                  "%)";
          }
        },
        {
          key: "win_point",
          label: "Trúng",
          sortable: false
        },
        { key: "actions", label: "Action", sortable: true }
      ],
      optionPerpage: [
        // { value: 1, text: "1" },
        // { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 30, text: "30" },
        { value: 50, text: "50" },
        { value: 100, text: "100" }
      ]
    };
  },
  created() {
    this.bindData();
    this.fetchAmountReport();
  },
  watch: {
    $route() {
      this.bindData();
      this.fetchAmountReport();
    },
    currentPage: function(nVal) {
      this.currentPage = nVal;
      this.fetchAmountReport();
    },
    perpage: function(nVal) {
      this.perpage = nVal;
      this.fetchAmountReport();
    }
  },
  methods: {
    async fetchAmountReport() {
      let res = await this.$http.get("/report/summary", {
        params: {
          from: this.from,
          to: this.to,
          page: this.currentPage,
          game: this.game,
          search: this.search,
          perpage: this.perpage
        }
      });

      if (res.data.code == 0) {
        this.datas = res.data.data.detail;
        this.currentPage = res.data.data.page;
        this.perPage = res.data.data.item_per_page;
        this.totalRows = res.data.data.item_per_page * res.data.data.total_page;
        if (res.data.data.total != null) this.summary = res.data.data.total;
      }
    },
    bindData() {
      this.from = this.$route.query.from
        ? moment(this.$route.query.from, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.to = this.$route.query.to
        ? moment(this.$route.query.to, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.game = this.$route.query.game ? this.$route.query.game : -1;
      this.search = this.$route.query.search ? this.$route.query.search : "";
    },
    format(money) {
      return Utilities.currencyReport(money);
    },
    formatWinloss(money) {
      let cls = money < 0 ? "text-danger" : "text-primary";
      let m =
        "<span class=' " +
        cls +
        "'>" +
        Utilities.currencyReport(money) +
        "</span>";
      return m;
    }
    // labelPercent(percent){
    //     return "Theo "+ percent +"%";
    // }
  }
};
</script>
<style>
.report-table .table.b-table > thead > tr > [aria-sort="none"],
.report-table .table.b-table > tfoot > tr > [aria-sort="none"] {
  background-image: none;
}
.report-table .table th,
.table td {
  border-bottom: none;
  border-top: none;
}
</style>
