<template>
  <b-container ref="bsContainer" class="bao-so px-3">
    <div class="px-3_ mb-3_ mt-3_">
      <b-row>
        <b-col cols="12">
          <b-input-group class="mb-2" size="sm" prepend="Nợ cũ:" label="">
            <b-form-input
              v-model="no_cu"
              type="text"
              placeholder="-123 hoặc +123 (+là Lời -là Lỗ)"
            ></b-form-input>
            <div class="input-group-append">
              <button
                class="btn btn-outline-success"
                type="button"
                id="button-addon2"
                @click="generateContent"
              >
                Cập nhật
              </button>
            </div>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group class="mb-2" size="sm" prepend="Nhân về:" label="">
            <b-form-input
              v-model="nhan_ve_auto"
              type="text"
              placeholder="0.95"
            ></b-form-input>
            <div class="input-group-append">
              <button
                class="btn btn-outline-success"
                type="button"
                id="button-addon2"
                @click="actionNhanVe"
              >
                Cập nhật
              </button>
            </div>
          </b-input-group>
        </b-col>
        <b-col cols="6" class="pr-0_">
          <b-input-group class="mb-2" size="sm" prepend="Thực thu:">
            <b-input-group-append is-text>
              <b-form-checkbox
                v-model="is_thuc_thu"
                @change="thucThuChange"
              ></b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="6" class="pr-0_ pl-0_">
          <b-input-group class="mb-2" size="sm" prepend="Tách 2c:">
            <b-input-group-append is-text>
              <b-form-checkbox v-model="is_tach_2c"></b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12" class="pr-0_ pl-0_ mb-2">
          <b-button @click="copyBaoSo" variant="success" size="sm" class="w-100"
            >Copy</b-button
          >
        </b-col>
      </b-row>
      <b-form-textarea
        rows="12"
        class="mb-2"
        v-model="baoSoContent"
      ></b-form-textarea>
    </div>
  </b-container>
</template>
<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import { Utilities } from "@/utilities";
import moment from "moment";

// 30/03, T3
// (Co )khach
// MN: 2c: 33,400 3c: 92,188
// Trúng:
// Cái lời: -84,972
// MB: 2c: 20,200 3c: 61,710
// Trúng: 2c:200n 3c:87n
// Cái lỗ: 16,289
// Tổng: cái lời -68,684
// Tiền cũ: 0
// Total: cái lời -68,684

export default {
  name: "Baoso",
  props: ["playerId", "from", "to"],
  data() {
    return {
      no_cu: "",
      nhan_ve: 1,
      nhan_ve_auto: 0.95,
      is_thuc_thu: false,
      is_tach_2c: false,
      baoSoData: [],
      player: "",
      playerType: null,
      baoSoContent: ""
    };
  },
  computed: {
    ...mapGetters(["game", "userSetting"]),
    baoSoDate: function() {
      let f = moment(this.from).format("DD/MM, dd");
      let t = moment(this.to).format("DD/MM, dd");
      let date = f === t ? f : f + " -> " + t;
      return date;
    }
  },
  watch: {
    is_thuc_thu: async function(newVal) {
      newVal;
      await this.$nextTick();
      this.generateContent();
    },
    is_tach_2c: async function(newVal) {
      newVal;
      await this.$nextTick();
      this.generateContent();
    }
    // nhan_ve: async function (newVal) {
    //   newVal;
    //   await this.$nextTick();
    //   this.generateContent();
    // },
    // no_cu: async function (newVal) {
    //   newVal;
    //   await this.$nextTick();
    //   this.generateContent();
    // },
  },
  methods: {
    async thucThuChange(value) {
      let thucthu = value ? 1 : 0;
      await this.$http.post("/user/setting", {
        thucthu: thucthu
      });
      await this.$store.dispatch("getUserSetting");
    },
    async fetchBaoSo() {
      // let playerId = this.$route.params.playerId;
      let res = await this.$http.get(
        "/report/bao-so/" +
          this.playerId +
          "?from=" +
          this.from +
          "&to=" +
          this.to
      );
      if (res.data.code == 0) {
        this.player = res.data.data.player;
        this.playerType = res.data.data.type;
        this.baoSoData = res.data.data.rp;

        let title_giao_nhan = this.playerType == 0 ? "Nhận" : "Giao";
        this.$emit(
          "title-bao-so",
          "Báo sổ: " + this.player + " (" + title_giao_nhan + ")"
        );
        this.generateContent();
      }
    },
    copyBaoSo() {
      let vm = this;
      vm.$copyText(vm.baoSoContent, vm.$refs.bsContainer).then(
        function() {
          vm.makeToast("Thành công !", "success", true);
        },
        function() {
          vm.makeToast("Ko thành công !", "danger", true);
        }
      );
      this.$emit("hide-bao-so");
    },
    makeToast(content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: "Copy báo sổ",
        autoHideDelay: 5000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right mb-5"
      });
    },
    actionNhanVe() {
      this.nhan_ve = this.nhan_ve_auto;
      this.generateContent();
    },
    generateContent() {
      let content = this.baoSoDate + "\n" + this.player + "\n";

      let vm = this;
      let totalWinloss = 0;
      let nhanveTotal = 0;

      let bs = this.baoSoData.map(function(e) {
        let turnOver = [];
        let ct = "";
        let game = vm.game[e.game_id][0].code.toUpperCase();

        let dd2c = e.to_2dd;
        let bl2c = e.to_2dbl;

        if (vm.is_tach_2c) {
          if (dd2c > 0) turnOver.push("2cAB: " + Utilities.baoSoFormat(dd2c));
          if (bl2c > 0) turnOver.push("2cLo: " + Utilities.baoSoFormat(bl2c));
        } else {
          turnOver.push("2c: " + Utilities.baoSoFormat(dd2c + bl2c));
        }

        if (e.to_3d > 0) turnOver.push("3c: " + Utilities.baoSoFormat(e.to_3d));
        if (e.to_4d > 0) turnOver.push("4c: " + Utilities.baoSoFormat(e.to_4d));
        if (e.to_da > 0) turnOver.push("da: " + Utilities.baoSoFormat(e.to_da));
        if (e.to_dx > 0) turnOver.push("dx: " + Utilities.baoSoFormat(e.to_dx));
        if (vm.is_thuc_thu)
          turnOver.push(`(ThựcThu: ${Utilities.baoSoFormat(e.amount)})`);

        let win = [];
        if (vm.is_tach_2c) {
          if (e.wp_2dd > 0)
            win.push("2cAB: " + Utilities.thousandFormat(e.wp_2dd) + "n");
          if (e.wp_2dbl > 0)
            win.push("2cLo: " + Utilities.thousandFormat(e.wp_2dbl) + "n");
        } else {
          if (e.wp_2dd > 0 || e.wp_2dbl > 0)
            win.push(
              "2c: " + Utilities.thousandFormat(e.wp_2dd + e.wp_2dbl) + "n"
            );
        }

        if (e.wp_3d > 0)
          win.push("3c: " + Utilities.thousandFormat(e.wp_3d) + "n");
        if (e.wp_4d > 0)
          win.push("4c: " + Utilities.thousandFormat(e.wp_4d) + "n");
        if (e.wp_da > 0)
          win.push("da: " + Utilities.thousandFormat(e.wp_da) + "n");
        if (e.wp_dx > 0)
          win.push("dx: " + Utilities.thousandFormat(e.wp_dx) + "n");

        let status = "";
        let nhanVe = 0;
        totalWinloss += e.winloss;
        if (vm.playerType == 0) {
          // Nhan
          status = e.winloss > 0 ? "lỗ" : "lời";
          nhanVe =
            e.winloss >= 0 || vm.nhan_ve == 1 ? 0 : e.winloss * vm.nhan_ve;
        } else {
          status = e.winloss > 0 ? "lời" : "lỗ";
          nhanVe =
            e.winloss <= 0 || vm.nhan_ve == 1 ? 0 : e.winloss * vm.nhan_ve;
        }

        nhanveTotal += nhanVe == 0 ? e.winloss : nhanVe;

        let nhanveText =
          nhanVe == 0
            ? ""
            : `(${vm.nhan_ve} -> ${Utilities.baoSoFormat(nhanVe)})`;
        ct = `${game}: ${turnOver.join(" ")}\nTrúng: ${win.join(
          " "
        )}\nCái ${status}: ${Utilities.baoSoFormat(e.winloss)} ${nhanveText}`;

        return ct;
      });

      let totalStatus = "";
      let finalStatus = "";

      if (vm.playerType == 0) {
        // Nhan
        totalStatus = totalWinloss > 0 ? "lỗ" : "lời";
        // nhanve = totalWinloss >= 0 ? totalWinloss : totalWinloss * vm.nhan_ve;
        finalStatus = nhanveTotal + vm.no_cu * 1000 > 0 ? "lỗ" : "lời";
      } else {
        totalStatus = totalWinloss > 0 ? "lời" : "lỗ";
        // nhanve = totalWinloss <= 0 ? totalWinloss : totalWinloss * vm.nhan_ve;
        finalStatus = nhanveTotal + vm.no_cu * 1000 > 0 ? "lời" : "lỗ";
      }

      this.baoSoContent =
        content +
        bs.join("\n") +
        `\nTổng: cái ${totalStatus} ${Utilities.baoSoFormat(
          nhanveTotal
        )}\nTiền cũ: ${Utilities.thousandFormat(
          vm.no_cu
        )}\nTotal: cái ${finalStatus} ${Utilities.baoSoFormat(
          nhanveTotal + vm.no_cu * 1000
        )}`;
    }
  },
  created() {
    moment.locale("vi");

    this.is_thuc_thu =
      this.userSetting && this.userSetting.thucthu == 1 ? true : false;

    this.fetchBaoSo();
  }
};
</script>
<style>
.bao-so .card-deck {
  text-align: left;
}
.modal-dialog.modal-md {
  width: 100vw;
}
</style>
