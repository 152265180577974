<template>
  <b-container class="report">
    <div class="px-3">
      <b-row class="mt-2 mb-2">
        <b-col cols="4" class="text-left">
          <b-form-select
            size="sm"
            v-model="report_show_type"
            @change="reportShowTypeChange"
          >
            <b-form-select-option :value="1">Danh sách</b-form-select-option>
            <b-form-select-option :value="2">Bảng</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="8" sm="12" class="text-right">
          <b-button-group size="sm">
            <b-button variant="danger" @click="confirmDeleteSms">
              Xóa tin
            </b-button>
            <b-button variant="outline-danger" :to="{ name: 'betWarning' }">
              Cảnh báo
            </b-button>
            <b-button
              variant="outline-success"
              :to="{ name: 'reportSummary', query: { from: from, to: to } }"
            >
              Tổng kết
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row align-v="center" class="mb-2">
        <b-col sm="6" cols="7" class="mb-2 text-right">
          <date-range
            :from="from"
            :to="to"
            @date-change="dateChange"
          ></date-range>
        </b-col>
        <b-col sm="6" cols="5" class="mb-2 text-left">
          <b-form-select size="sm" v-model="game">
            <b-form-select-option :value="-1">Tất cả</b-form-select-option>
            <b-form-select-option :value="1">Bắc</b-form-select-option>
            <b-form-select-option :value="2">Trung</b-form-select-option>
            <b-form-select-option :value="3">Nam</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col sm="6" cols="12" class="mb-2 text-left">
          <b-input-group class="input-group-sm">
            <b-form-input
              v-model="search"
              placeholder="Nhập tên khách hàng"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-success" @click="actionSeachName"
                >Tìm kiếm</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
    <div class="content mb-5">
      <div v-if="report_show_type == 2">
        <report-table @on-bao-so="baoSoSelected"></report-table>
      </div>
      <div v-else>
        <report-list @on-bao-so="baoSoSelected"></report-list>
      </div>
    </div>
    <b-modal id="modal-baoso" ref="modal-baoso" hide-footer :title="titleBaoSo">
      <bao-so
        :playerId="player_bao_so"
        :from="this.from"
        :to="this.to"
        @title-bao-so="setTitleBaoSo"
        @hide-bao-so="hideBaoSo"
      >
      </bao-so>
    </b-modal>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import ReportTable from "@/components/report/ReportTable";
import ReportList from "@/components/report/ReportList";
import DateRange from "@/components/DateRange";
import BaoSo from "./BaoSo";

export default {
  name: "Report",
  components: {
    "bao-so": BaoSo,
    "report-table": ReportTable,
    "report-list": ReportList,
    "date-range": DateRange
  },
  data() {
    return {
      player_bao_so: 0,
      titleBaoSo: "",
      game: -1,
      report_show_type: 2,
      search: "",
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD")
    };
  },
  computed: {
    ...mapGetters(["currentUser", "userSetting"])
  },
  created() {
    this.report_show_type =
      this.userSetting && this.userSetting.report_show_type
        ? this.userSetting.report_show_type
        : 2;

    this.from = this.$route.query.from
      ? moment(this.$route.query.from, "YYYY-MM-DD").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    this.to = this.$route.query.to
      ? moment(this.$route.query.to, "YYYY-MM-DD").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    this.game = this.$route.query.game ? this.$route.query.game : -1;
    this.search = this.$route.query.search ? this.$route.query.search : "";
  },
  watch: {
    userSetting: function(nVal) {
      this.report_show_type = nVal.report_show_type ? nVal.report_show_type : 2;
    },
    game: async function(newVal) {
      await this.$nextTick();

      this.search = "";
      this.$router.replace({
        name: this.$router.history.current.name,
        params: this.$router.history.current.params,
        query: {
          ...this.$router.history.current.query,
          game: newVal,
          search: ""
        }
      });
    }
  },
  methods: {
    async reportShowTypeChange() {
      await this.$http.post("/user/setting", {
        report_show_type: this.report_show_type
      });
      await this.$store.dispatch("getUserSetting");
    },
    baoSoSelected(player_id) {
      this.player_bao_so = player_id;
    },
    setTitleBaoSo(title) {
      this.titleBaoSo = title;
    },
    hideBaoSo() {
      setTimeout(() => {
        this.$refs["modal-baoso"].hide();
      }, 1000);
    },
    async actionSeachName() {
      await this.$nextTick();

      this.$router.replace({
        name: this.$router.history.current.name,
        params: this.$router.history.current.params,
        query: {
          ...this.$router.history.current.query,
          search: this.search
        }
      });
    },
    async dateChange(date) {
      await this.$nextTick();
      this.from = date.from;
      this.to = date.to;

      if (
        this.$router.history.current.query.from == this.from &&
        this.$router.history.current.query.to == this.to
      )
        return;

      this.game = -1;
      this.search = "";
      this.$router.replace({
        name: this.$router.history.current.name,
        params: this.$router.history.current.params,
        query: {
          ...this.$router.history.current.query,
          from: date.from,
          to: date.to,
          search: "",
          game: -1
        }
      });
    },
    confirmDeleteSms() {
      let vm = this;

      let date =
        this.from == this.to ? this.from : this.from + " -> " + this.to;
      let msg = "Xóa toàn bộ tin ngày " + date;

      vm.$bvModal
        .msgBoxConfirm(msg, {
          title: "Xác nhận xóa tin",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Xóa",
          cancelTitle: "Không",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(async value => {
          if (value) {
            let res = await vm.$http.post("bets/deleteFilter", {
              from: vm.from,
              to: vm.to
            });

            if (res.data.code == 0) {
              window.location.reload();
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style>
.report .bt2 {
  border-top: 2px solid #dcdcdc;
}
.report .name {
  white-space: pre-wrap !important;
}
.report-mobile-level-one .title {
  font-size: 14px;
}
.report-mobile-level-one .content {
  font-size: 16px;
}
.report-mobile-level-one .btn-so {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 0px;
  margin-top: -5px;
  margin-right: -12px;
}
.report-mobile-level-one .text-left {
  text-align: left;
}
</style>
