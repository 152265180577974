<template>
  <div>
    <div class="px-3">
      <b-row>
        <b-col cols="12" class="mb-2 text-left">
          <b-form-select size="sm" v-model="turn_over_detail_selected">
            <b-form-select-option :value="1"
              >Hiện 2D,3D,4D,DA,DX
            </b-form-select-option>
            <b-form-select-option :value="0"
              >Ẩn 2D,3D,4D,DA,DX</b-form-select-option
            >
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <b-list-group class="report-mobile-level-one">
      <b-list-group-item class="flex-column align-items-start summary">
        <div class="text-center text-success">
          <h6 class="mb-1">Tổng kết</h6>
        </div>
        <div>
          <b-row>
            <b-col class="text-sub text-left">Tiền xác</b-col>
            <b-col class="text-sub text-left">Thực thu</b-col>
            <b-col class="text-sub text-left">Trúng</b-col>
            <b-col class="text-sub text-left">Thắng/thua</b-col>
          </b-row>
          <b-row>
            <b-col class="text-main text-left">{{
              format(summary.turn_over)
            }}</b-col>
            <b-col class="text-main text-left">{{
              format(summary.amount)
            }}</b-col>
            <b-col class="text-main text-left">{{ format(summary.win) }}</b-col>
            <b-col
              class="text-main text-left"
              v-html="formatWinloss(summary.winloss)"
            ></b-col>
          </b-row>
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="flex-column align-items-start"
        v-for="(item, itemIndex) in tableData"
        :key="'a' + itemIndex"
      >
        <div class="d-flex w-100 justify-content-between">
          <h6 class="mb-1 text-uppercase_">
            <router-link
              :to="{
                name: 'reportPlayerDetail',
                params: { playerId: item.player_id },
                query: { from: from, to: to }
              }"
              ><small class="text-sub">{{ itemIndex + 1 }}.</small>
              {{ item.player }} ({{ item.total_sms }} tin)
            </router-link>
          </h6>
          <b-button
            variant="outline-success"
            class="btn-so"
            size="sm"
            v-b-modal.modal-baoso
            @click="$emit('on-bao-so', item.player_id)"
            >Sổ</b-button
          >
        </div>
        <div @click="gotoDetail(item.player_id)">
          <div class="pb-1" v-if="turn_over_detail_selected == 1">
            <b-row>
              <b-col class="text-sub text-left">2D</b-col>
              <b-col class="text-sub text-left">3D | 4D</b-col>
              <b-col class="text-sub text-left">Đá | Xiên</b-col>
            </b-row>
            <b-row>
              <b-col class="text-main text-left">{{
                format(item.turn_over_2d)
              }}</b-col>
              <b-col class="text-main text-left">{{
                format(item.turn_over_3d + item.turn_over_4d)
              }}</b-col>
              <b-col class="text-main text-left">{{
                format(item.turn_over_da | item.turn_over_dx)
              }}</b-col>
            </b-row>
          </div>
          <div class="pb-1">
            <b-row>
              <b-col class="text-sub text-left">Tiền xác</b-col>
              <b-col class="text-sub text-left">Thực thu</b-col>
              <b-col class="text-sub text-left">Trúng</b-col>
            </b-row>
            <b-row>
              <b-col class="text-main text-left">{{
                format(item.turn_over)
              }}</b-col>
              <b-col class="text-main text-left">{{
                format(item.amount)
              }}</b-col>
              <b-col class="text-main text-left">{{ format(item.win) }}</b-col>
            </b-row>
          </div>
          <b-row>
            <b-col class="text-sub text-left"></b-col>
            <b-col class="text-sub text-left" v-if="item.percent == 0"></b-col>
            <b-col class="text-sub text-left" v-else
              >Theo {{ item.percent }}%</b-col
            >
            <b-col class="text-sub text-left">Thắng/thua</b-col>
          </b-row>
          <b-row>
            <b-col></b-col>

            <b-col class="text-main text-left" v-if="item.percent == 0"></b-col
            ><b-col
              class="text-main text-left"
              v-else
              v-html="formatWinloss((item.winloss * item.percent) / 100.0)"
            ></b-col>

            <b-col
              class="text-main text-left"
              v-html="formatWinloss(item.winloss)"
            ></b-col>
          </b-row>
        </div>
      </b-list-group-item>
    </b-list-group>
    <infinite-loading :identifier="infiniteId" @infinite="fetchAmountReport">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>
<script>
import { Utilities } from "@/utilities";
import moment from "moment";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "report-list",
  data() {
    return {
      page: 1,
      tableData: [],
      summary: 0,
      infiniteId: +new Date(),
      game: -1,
      search: "",
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      turn_over_detail_selected: 1
    };
  },
  created() {
    this.bindData();
  },
  watch: {
    $route() {
      this.bindData();
      this.page = 1;
      this.tableData = [];
      this.summary = 0;
      this.infiniteId++;
    }
  },
  components: {
    InfiniteLoading
  },
  methods: {
    async fetchAmountReport($state) {
      let res = await this.$http.get("/report/summary", {
        params: {
          from: this.from,
          to: this.to,
          page: this.page,
          game: this.game,
          search: this.search
        }
      });

      if (res.data.code == 0) {
        if (res.data.data.detail.length == 0) {
          $state.complete();
          return;
        }

        this.tableData = [...this.tableData, ...res.data.data.detail];
        if (res.data.data.total != null) this.summary = res.data.data.total;
        this.page++;

        if (res.data.data.detail.length < res.data.data.item_per_page) {
          $state.complete();
        } else {
          $state.loaded();
        }
      }
    },
    bindData() {
      this.from = this.$route.query.from
        ? moment(this.$route.query.from, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.to = this.$route.query.to
        ? moment(this.$route.query.to, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.game = this.$route.query.game ? this.$route.query.game : -1;
      this.search = this.$route.query.search ? this.$route.query.search : "";
    },
    format(money) {
      return Utilities.currencyReport(money);
    },
    formatWinloss(money) {
      let cls = money < 0 ? "text-danger" : "text-primary";
      let m =
        "<span class=' " +
        cls +
        "'>" +
        Utilities.currencyReport(money) +
        "</span>";
      return m;
    },
    gotoDetail(player_id) {
      let r = {
        name: "reportPlayerDetail",
        params: { playerId: player_id },
        query: { from: this.from, to: this.to }
      };
      this.$router.replace(r);
    }
    // handleScroll() {
    //   let bottomOfWindow =
    //     document.documentElement.scrollTop + window.innerHeight ===
    //     document.documentElement.offsetHeight;
    //   if (bottomOfWindow) {
    //     console.log("end");
    //   }
    // },
  }
};
</script>
<style></style>
